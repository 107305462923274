import { useSyncExternalStore } from "react"

const BREAK_POINT_TABLET = 768

const useIsSmallScreen = (customBreakPoint = BREAK_POINT_TABLET) => {
  const isSmallScreen = useSyncExternalStore(onStoreChange, getSnapshot(customBreakPoint))

  return { isSmallScreen }
}

const onStoreChange = (callback: () => void) => {
  window.addEventListener("resize", callback)

  return () => {
    window.removeEventListener("resize", callback)
  }
}

const getSnapshot = (breakpoint: number) => () => window.innerWidth <= breakpoint

export { useIsSmallScreen }
