import { FC, useState } from "react"
import { isValid, format, parseISO } from "date-fns"
import { Reference } from "fhir"
import { useSearchParams } from "react-router-dom"
import { toZonedTime } from "date-fns-tz"
import { useMountEffect } from "primereact/hooks"

import { FiltersContextProvider, SkeletonLoader } from "commons"
import { formatsByTypes } from "data"
import { usePatient } from "patients"
import { useOrganization } from "organizations"

import { OrderFilter } from "../types"
import { OrdersContainer } from "./OrdersContainer"

const OrdersView: FC = () => {
  const [searchParams] = useSearchParams()
  const [initialRender, setInitialRender] = useState(true)

  const patientId = searchParams.get("patientId")
  const organizationId = searchParams.get("organizationId")

  const { patientRef, isLoading: isLoadingPatient } = usePatient(patientId as string, !!patientId && initialRender)
  const { organizationRef, isLoading: isLoadingOrg } = useOrganization(organizationId ?? undefined, initialRender)

  useMountEffect(() => setInitialRender(false))

  if (isLoadingPatient || isLoadingOrg)
    return (
      <>
        <SkeletonLoader loaderType="one-line" repeats={1} />
        <SkeletonLoader loaderType="form-two-cols" repeats={1} />
      </>
    )

  const initialFilters = parseParamsToFilters(searchParams, organizationRef, patientRef)

  return (
    <FiltersContextProvider
      initialFilters={initialFilters}
      setUrlFilters
      filtersKeysValueMap={{
        authored: (date) =>
          date && isValid(parseISO(new Date(date as string).toISOString()))
            ? format(date as string, formatsByTypes.ISO_8601_DATE)
            : undefined,
        occurrence: (date) =>
          date && isValid(parseISO(new Date(date as string).toISOString()))
            ? format(date as string, formatsByTypes.ISO_8601_DATE)
            : undefined,
        status: (array) => (Array.isArray(array) ? (array.length ? array.join(",") : undefined) : array?.toString()),
        organization: (org) => (org as Reference | undefined)?.id,
        patient: (pat) => (pat as Reference | undefined)?.id,
      }}
      filtersKeysNameMap={{ patient: "patientId", organization: "organizationId" }}
    >
      <OrdersContainer />
    </FiltersContextProvider>
  )
}

const DFAULT_FILTERS: OrderFilter = {
  type: undefined,
  patient: undefined,
  organization: undefined,
  status: undefined,
  authored: undefined,
  occurrence: undefined,
}

export { OrdersView }

const parseParamsToFilters = (
  searchParams: URLSearchParams,
  organizationRef?: Reference,
  patientRef?: Reference,
): OrderFilter => ({
  type: searchParams.get("type") || DFAULT_FILTERS.type,
  patient: searchParams.get("patientId")
    ? patientRef ?? ({ id: searchParams.get("patientId"), resourceType: "Patient" } as Reference)
    : DFAULT_FILTERS.patient,
  organization: searchParams.get("organizationId")
    ? organizationRef ?? ({ id: searchParams.get("organizationId"), resourceType: "Organization" } as Reference)
    : DFAULT_FILTERS.organization,
  status: searchParams.get("status") ? searchParams.get("status")?.split(",") : DFAULT_FILTERS.status,
  authored: searchParams.get("authored")
    ? toZonedTime(searchParams.get("authored") as string, "UTC")
    : DFAULT_FILTERS.authored,
  occurrence: searchParams.get("occurrence")
    ? toZonedTime(searchParams.get("occurrence") as string, "UTC")
    : DFAULT_FILTERS.occurrence,
})
