import { Formik } from "formik"

import { OrderFilter } from "../types"
import { OrdersFilters } from "./OrdersFilters"

const OrdersFiltersFormContiner = ({
  initialValues,
  hasActiveFilters,
  isSmallViewport,
  onSearch,
  onClearFilters,
}: Props) => (
  <Formik initialValues={initialValues} onSubmit={onSearch} enableReinitialize>
    <OrdersFilters
      onClearFilters={onClearFilters}
      hasActiveFilters={hasActiveFilters}
      isSmallViewport={isSmallViewport}
    />
  </Formik>
)

type Props = {
  initialValues: OrderFilter & { searchText?: string }
  hasActiveFilters?: boolean
  isSmallViewport?: boolean
  onSearch(filters: OrderFilter): void
  onClearFilters(): void
}

export { OrdersFiltersFormContiner }
