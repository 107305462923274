import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Form, useFormikContext } from "formik"
import { DropdownChangeEvent } from "primereact/dropdown"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import {
  AutoCompletePatientField,
  Button,
  DateField,
  DropdownField,
  MultiSelectField,
  OrganizationAutocomplete,
} from "commons"
import { srCategoryCodes } from "data"

import { OrderFilter } from "../types"
import { getStatusesByType } from "../utils"
import { classNames } from "primereact/utils"

const OrdersFilters = ({ hasActiveFilters, isSmallViewport, onClearFilters }: Props) => {
  const [params] = useSearchParams()
  const {
    values: { type, status },
    isSubmitting,
    setFieldValue,
  } = useFormikContext<OrderFilter>()
  const srCategories = Object.values(srCategoryCodes)

  const [statusCodes, setStatusCodes] = useState(() => {
    const type = params.get("type") ?? ""
    return getStatusesByType(type)
  })

  const handleChangeType = (e: DropdownChangeEvent) => {
    const type = e.value
    setStatusCodes(getStatusesByType(type))
  }

  useEffect(() => {
    const statusFiltered = status?.filter((x) => statusCodes.find((y) => y.code === x))
    setFieldValue("status", statusFiltered)
  }, [type])

  return (
    <Form className="flex flex-col h-full bg-white">
      <DropdownField
        label="Type"
        field="type"
        options={srCategories}
        optionLabel="display"
        horizontal={isSmallViewport}
        showClear
        inputClassName={classNames("text-sm", { slashed: isSmallViewport })}
        handleChange={handleChangeType}
      />
      <AutoCompletePatientField
        label="Patient"
        field="patient"
        horizontal={isSmallViewport}
        inputClassName={classNames("text-sm w-full", { slashed: isSmallViewport })}
      />
      <OrganizationAutocomplete
        horizontal={isSmallViewport}
        inputClassName={classNames("text-sm w-full", { slashed: isSmallViewport })}
        label="Organization"
        field="organization"
      />
      <MultiSelectField
        label="Status"
        field="status"
        options={statusCodes}
        optionLabel="display"
        horizontal={isSmallViewport}
        showClear
        inputClassName={classNames("text-sm", { slashed: isSmallViewport })}
      />
      <DateField
        label="Authored on"
        field="authored"
        horizontal={isSmallViewport}
        inputClassName={classNames("text-sm pl-2", { slashed: isSmallViewport })}
        maxDate={new Date()}
        showIcon={!isSmallViewport}
      />
      <DateField
        label="Occurrence"
        field="occurrence"
        horizontal={isSmallViewport}
        inputClassName={classNames("text-sm pl-2", { slashed: isSmallViewport })}
        maxDate={new Date()}
        showIcon={!isSmallViewport}
      />
      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || !hasActiveFilters}
          icon={faFilterSlash}
          onClick={onClearFilters}
          className="p-button-sm mr-3"
          buttonStyle="default"
        />
        <Button type="submit" label="Search" loading={isSubmitting} className="p-button-sm" />
      </div>
    </Form>
  )
}

type Props = {
  hasActiveFilters?: boolean
  isSmallViewport?: boolean
  onClearFilters(): void
}

export { OrdersFilters }
