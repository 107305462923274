import { Skeleton } from "primereact/skeleton"
import { type DataTableValue, DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { type ReactNode, Fragment } from "react"
import { classNames } from "primereact/utils"

const SkeletonLoader = ({
  loaderType,
  repeats = 1,
  containerClassName,
  tableTitle,
  columns = [
    { header: "", headerStyle: undefined },
    { header: "", headerStyle: undefined },
  ],
  extraLine,
  skeletonItemClassName,
  skeletonShape = "circle",
  skeletonSize = "4rem",
}: Props) => {
  if (repeats < 1) repeats = 1

  if (loaderType === "table") {
    const rows = Array.from({ length: repeats }) as DataTableValue[]

    return (
      <div className={containerClassName}>
        {tableTitle ?? ""}
        <DataTable value={rows}>
          {columns?.map((col, index) => {
            col.body = col.body ?? <Skeleton />
            return (
              <Column
                key={col.header ?? index}
                header={col.header}
                headerClassName={col.headerClass}
                headerStyle={col.headerStyle}
                body={col.body}
              ></Column>
            )
          })}
        </DataTable>
      </div>
    )
  }

  if (loaderType === "mkCard")
    return (
      <>
        {Array.from({ length: repeats }, (_, k) => (
          <div key={k} className="border border-slate-300 rounded-lg p-3 xl:p-5">
            <div className="flex xl:flex-col gap-2 lg:gap-4 grow h-full">
              <Skeleton shape="rectangle" size="7rem" className="w-28 items-center xl:m-auto" />
              <div className="flex flex-col gap-2 mt-2 xl:mt-3 text-left">
                <Skeleton height="1.5rem" />
                <Skeleton height="1.5rem" />
                <Skeleton height="1.5rem" />
              </div>
              <div className="flex flex-col gap-2 grow justify-end">
                <Skeleton height="1.8rem" />

                <span className="flex justify-between gap-2">
                  <Skeleton height="3.1rem" />
                  <Skeleton height="3.1rem" width="40%" />
                </span>
              </div>
            </div>
          </div>
        ))}
      </>
    )

  if (loaderType === "form-two-cols")
    return (
      <div className="form-container p-3 xl:p-5 grid grid-cols-2 w-full gap-10 h-80">
        {Array.from({ length: repeats }, (_, k) => (
          <Fragment key={k}>
            <div className="flex flex-col gap-2 lg:gap-4 grow">
              <div className="flex flex-col gap-2">
                <Skeleton height="2rem" />
                <Skeleton height="2rem" />
                <Skeleton height="2rem" />
                <Skeleton height="2rem" />
              </div>
              <div className="flex flex-col gap-2 grow justify-end">
                <Skeleton height="4rem" />
                <Skeleton height="4rem" />
              </div>
            </div>
            <div className="flex flex-col gap-2 lg:gap-4 grow h-full">
              <Skeleton height="10rem" />
              <Skeleton height="10rem" />
            </div>
          </Fragment>
        ))}
      </div>
    )

  if (loaderType === "min-card")
    return (
      <div className={containerClassName}>
        {Array.from({ length: repeats }, (_, k) => (
          <div key={k} className="border border-gray-300 p-4 rounded-lg">
            <Skeleton height={skeletonSize} />
          </div>
        ))}
      </div>
    )

  return (
    <div className={containerClassName}>
      {Array.from({ length: repeats }, (_, k) => (
        <div
          key={k}
          className={classNames(
            loaderType !== "plain-text" && "flex border-solid border-b border-slate-200/90 p-4",
            skeletonItemClassName ?? "last:border-none last:mb-6",
          )}
        >
          {loaderType === "list" && <Skeleton shape={skeletonShape} size={skeletonSize} className="mr-2" />}
          <div className="flex-1">
            <Skeleton className="my-2" />
            {(loaderType === "list" || loaderType === "two-lines") && <Skeleton className="my-2" />}
            {extraLine && <Skeleton className="my-2 max-w-sm" />}
          </div>
        </div>
      ))}
    </div>
  )
}

type LoaderType = "one-line" | "two-lines" | "list" | "table" | "mkCard" | "plain-text" | "min-card" | "form-two-cols"
type SkeletonPassThroughType = "rectangle" | "circle" | undefined

type SkeletonColumn = {
  fieldId?: string
  header?: string
  headerStyle?: object
  headerClass?: string
  body?: ReactNode
}

type Props = {
  loaderType: LoaderType
  repeats?: number
  containerClassName?: string
  skeletonItemClassName?: string
  tableTitle?: ReactNode
  columns?: SkeletonColumn[]
  skeletonShape?: SkeletonPassThroughType
  skeletonSize?: string
  extraLine?: boolean
}

export type { SkeletonColumn }

export { SkeletonLoader }
