import { classNames } from "primereact/utils"
import { CSSProperties, useRef, type FC, type PropsWithChildren, type ReactNode } from "react"
import { OverlayPanel } from "primereact/overlaypanel"
import { faFilter } from "@fortawesome/pro-solid-svg-icons"

import { type Action, Button, FooterActions, SearchInput } from "commons"

const MasterDetailView: FC<PropsWithChildren<Props>> = ({
  actions,
  children,
  filters,
  searchText,
  loading,
  placeholder,
  onSearch,
  headerAction,
  headerText,
  overlayStyles,
  isSmallViewport = false,
  hasActiveFilters = false,
  containerClassName = "overflow-auto grow min-h-max",
}) => {
  const overlayFilter = useRef<OverlayPanel>(null)

  return (
    <>
      <header className="flex flex-wrap gap-y-2 lg:flex-nowrap items-center w-full lg:justify-between py-4 px-6 border-b drop-shadow">
        <SearchInput
          initialValue={searchText}
          search={onSearch}
          autoFocus={false}
          placeholder={placeholder}
          isLoading={loading}
          className="flex min-w-80 xl:min-w-64"
        />
        {!!filters && isSmallViewport && (
          <>
            <Button
              icon={faFilter}
              iconClassName={!hasActiveFilters ? "text-slate-400" : "text-primary"}
              buttonStyle="default"
              size="lg"
              className="h-full w-12 text-center justify-center ml-2"
              onClick={(e) => overlayFilter?.current?.toggle(e)}
              loading={loading}
            />
            <OverlayPanel
              ref={overlayFilter}
              showCloseIcon={false}
              dismissable
              id="overlay_panel"
              className="form-panel pb-3"
              breakpoints={{ "1330px": "25vw", "1024px": "45vw", "960px": "75vw", "640px": "90vw" }}
              style={overlayStyles}
            >
              <div className="flex flex-col bg-white gap-4">{filters}</div>
            </OverlayPanel>
          </>
        )}

        <div className="inline-flex gap-x-3 ml-2 overflow-hidden lg:justify-end w-full">
          {!!headerText && <p className="truncate text-sm text-gray-400">{headerText}</p>}
          {!!headerAction && (
            <Button {...headerAction} onClick={headerAction.command} size="sm" buttonStyle="primary" />
          )}
        </div>
      </header>
      <section className="grid grid-cols-5 gap-4 grow lg:p-4 bg-gray-100 h-full overflow-hidden @container">
        {!!filters && !isSmallViewport && (
          <div className="flex flex-col h-max bg-white gap-4 col-span-full @4xl:col-span-2 @6xl:col-span-1 @4xl:rounded-2xl p-4">
            {filters}
          </div>
        )}
        <div
          className={classNames(
            "col-span-full @4xl:col-span-3 @6xl:col-span-4 @4xl:rounded-2xl px-4 bg-white",
            containerClassName,
          )}
        >
          {children}
        </div>
      </section>
      {!!actions?.length && <FooterActions actions={actions} />}
    </>
  )
}

type Props = {
  actions?: Action[]
  filters?: ReactNode
  searchText?: string
  hasActiveFilters?: boolean
  isSmallViewport?: boolean
  placeholder?: string
  loading?: boolean
  onSearch: (searchText: string) => void
  containerClassName?: string
  headerText?: string
  headerAction?: Action
  overlayStyles?: CSSProperties
}

export { MasterDetailView }
