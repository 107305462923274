import { useInfiniteQuery } from "@tanstack/react-query"
import {
  Bundle,
  getResource,
  getResources,
  getResourcesAsIndex,
  getResourcesByTypeAsIndex,
  humanNameAsString,
  isBundle,
  Parameters,
  Patient,
  ServiceRequest,
  SettingDefinition,
  Task,
} from "fhir"
import { useMemo } from "react"

import { IClient, useClient } from "api"
import { BRANDING_SETTING_CODES, srCategoryCodes } from "data"
import { formatDate } from "utils"

import { ordersQueryKeys } from "../query-keys"
import { Order } from "../types"
import { getCategory, getOrderDetail } from "../utils"

const useOrganizationOrders = (
  organizationId?: string,
  category?: string,
  patientId?: string,
  performer?: string,
  status?: string,
  authored?: Date,
  occurrence?: Date,
  searchText?: string,
) => {
  const { search, transaction } = useClient(IClient.AIDBOX)
  const queryKey = ordersQueryKeys.list(
    organizationId,
    category,
    patientId,
    performer,
    status,
    authored,
    occurrence,
    searchText,
  )

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<OrdersQueryData, Error>({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const statusFieldFilter = category === srCategoryCodes["lab-order"].code ? "orderDetail" : "status"

      const filters = new URLSearchParams({
        ...(organizationId ? { "subject:Patient.organization": organizationId } : {}),
        ...(searchText
          ? {
              _filter: `subject:Patient.termsearch eq ${searchText} or identifier eq ${searchText} or id eq ${searchText}`,
            }
          : {}),
        ...(performer ? { "performer:text": performer } : {}),
        category: getCategory(category),
        ...(getOrderDetail(category) ? { orderDetail: getOrderDetail(category) } : {}),
        ...(status ? { [statusFieldFilter]: status } : {}),
        ...(patientId ? { subject: patientId } : {}),
        ...(authored ? { authored: formatDate(authored) } : {}),
        ...(occurrence ? { occurrence: formatDate(occurrence) } : {}),
        _include: "subject",
        _revinclude: "Task:focus:ServiceRequest",
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-createdAt",
        "reasonCode:not": "invalid-submission",
      })
      const bundle = await search("ServiceRequest", filters, undefined, signal)

      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const patientsIndexed = getResourcesAsIndex<Patient>(bundle, "Patient")
      const indexedTasks = getResourcesByTypeAsIndex<Task>(bundle, "Task", ({ focus }) => focus?.id)

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      const uniqueOrgsIds = getUniqueOrgsIds(patientsIndexed)

      const batchBundle: Bundle = {
        resourceType: "Bundle",
        type: "batch",
        entry: uniqueOrgsIds.map((orgId) => ({
          request: {
            method: "POST",
            url: "/Setting/$resolve",
          },
          resource: {
            resourceType: "Parameters",
            parameter: [
              {
                name: "owner",
                value: {
                  Reference: {
                    id: orgId,
                    resourceType: "Organization",
                  },
                },
              },
              {
                name: "code",
                value: {
                  code: "branding",
                },
              },
            ],
          } as Parameters,
        })),
      }

      const settingsBundle = await transaction<Bundle>(batchBundle)

      const ehrUrls = getEhrUrls(settingsBundle)
      const orgUrlHash: Record<string, string> = {}

      uniqueOrgsIds.forEach((orgId, index) => {
        orgUrlHash[orgId as string] = ehrUrls[index]
      })

      const orders = serviceRequests.reduce<Order[]>((prev, sr) => {
        if (sr.subject.id && patientsIndexed[sr.subject.id]) {
          const patient = patientsIndexed[sr.subject.id]
          const patientOrg = patient?.managingOrganization

          const order: Order = {
            sr: {
              ...sr,
              subject: {
                ...sr.subject,
                display: humanNameAsString(patient?.name?.[0]),
              },
            },
            ehrUrl: orgUrlHash[patientOrg?.id ?? ""],
            orgId: patientOrg?.id ?? "",
            orgName: patientOrg?.display ?? "",
            task: indexedTasks?.[sr.id as string],
          }

          return [...prev, order]
        }

        return prev
      }, [] as Order[])

      return {
        orders,
        serviceRequests,
        orgUrlHash,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey } },
  })

  const orders = useMemo(() => data?.pages.flatMap((page) => page.orders) ?? [], [data?.pages])

  return {
    orders,
    isLoading,
    count: orders.length,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

const getEhrUrls = (bundle: Bundle): string[] => {
  const ehrUrls = [] as string[]

  bundle?.entry?.forEach(({ resource }) => {
    if (isBundle(resource)) {
      const sd = getResource<SettingDefinition>(resource, "SettingDefinition")

      if (sd.item) {
        const ehrUrlItem = sd.item.find(({ code }) => code?.code === BRANDING_SETTING_CODES.ehrUrl)

        if (ehrUrlItem?.default?.string) {
          ehrUrls.push(ehrUrlItem.default.string)
        }
      }
    }
  })

  return ehrUrls
}

const getUniqueOrgsIds = (patientsIndexed: Record<string, Patient>) =>
  Array.from(
    new Set(
      Object.values(patientsIndexed).reduce((prev, { managingOrganization }) => {
        if (managingOrganization?.id) {
          return [...prev, managingOrganization.id]
        }

        return prev
      }, [] as string[]),
    ),
  )

type OrdersQueryData = {
  orders: Order[]
  next: number | undefined
  total: number
}

export { useOrganizationOrders }
