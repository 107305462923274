import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import { Field, FieldProps, ErrorMessage, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { FC, useEffect, useRef } from "react"
import { Calendar } from "primereact/calendar"
import { FormEvent } from "primereact/ts-helpers"
import { format, parse, roundToNearestMinutes } from "date-fns"

import { Button } from "../components/Buttons"
import { FormatTypes } from "../types"

const DateField: FC<Props> = ({
  field,
  label,
  className,
  dateFormat,
  showTime,
  timeOnly,
  view = "date",
  minDate,
  maxDate,
  selectionMode = "single",
  readOnlyInput,
  horizontal,
  inputClassName,
  showIcon = true,
  onChange,
  validation,
}) => {
  const cal = useRef<Calendar>(null)
  const { getFieldMeta, setFieldValue } = useFormikContext()
  const initVal = getFieldMeta(field).initialValue as string | Date | Date[]

  const getParsedValue = (value: Date | Date[] | null | undefined, timeOnly: boolean = false) => {
    if (Array.isArray(value))
      return timeOnly
        ? [format(value[0], "HH:mm:ss"), format(value[1], "HH:mm:ss")]
        : [value[0]?.toISOString(), value[1]?.toISOString()]
    else return value ? (timeOnly ? format(value, "HH:mm:ss") : value.toISOString()) : null
  }

  const roundDate = (val: Date | string, timeOnly: boolean = false) =>
    roundToNearestMinutes(timeOnly ? parse(val as string, "HH:mm:ss", new Date()) : new Date(val), {
      nearestTo: 15,
    })

  useEffect(() => {
    if (initVal) {
      const initDate = Array.isArray(initVal)
        ? [roundDate(initVal[0], timeOnly), roundDate(initVal[1], timeOnly)]
        : roundDate(initVal, timeOnly)
      const parsedValue = getParsedValue(initDate, timeOnly)
      initVal !== parsedValue && setFieldValue(field, parsedValue)
    } else cal.current?.updateViewDate(null, roundToNearestMinutes(new Date(), { nearestTo: 15 }))
  }, [])

  const handleChangeCalendar = (e: FormEvent<Date> | FormEvent<Date[]> | FormEvent<(Date | null)[]>) => {
    const { name, value } = e.target

    if (Array.isArray(value)) {
      setFieldValue(name, value)
    } else {
      setFieldValue(name, getParsedValue(value, timeOnly))
    }

    onChange?.(value ?? undefined)
  }

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value }, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => {
        const footer = () => (
          <div className="flex justify-end p-2 border-t pt-4">
            <Button
              label="Accept"
              icon={faCheck}
              buttonStyle="default"
              onClick={async () => {
                const calDate = cal.current?.getCurrentDateTime()
                if (calDate) {
                  if (Array.isArray(calDate)) {
                    await setFieldValue(name, calDate)
                  } else {
                    await setFieldValue(name, getParsedValue(calDate, timeOnly))
                  }
                  onChange?.(calDate)
                }
                cal.current?.hide()
              }}
            />
          </div>
        )

        return (
          <div
            className={classNames(
              "field space-y-2 relative",
              horizontal ? "inline-flex justify-between" : "flex flex-col",
              className,
            )}
          >
            {label && (
              <label
                htmlFor={name}
                className={classNames("text-sm font-medium text-gray-700 mb-2", {
                  "mr-3 mb-0 mt-4": horizontal,
                })}
              >
                {label}
              </label>
            )}
            <Calendar
              ref={cal}
              id={name}
              name={name}
              value={
                typeof value === "string"
                  ? timeOnly
                    ? parse(
                        value.split("T")[1] ?? value,
                        value.includes("Z") ? "HH:mm:ss.SSSX" : "HH:mm:ss",
                        new Date(),
                      )
                    : new Date(value)
                  : value
              }
              onChange={handleChangeCalendar}
              minDate={minDate && initVal ? new Date(Array.isArray(initVal) ? initVal[0] : initVal) : minDate}
              maxDate={maxDate}
              showIcon={showIcon}
              showTime={showTime}
              timeOnly={timeOnly}
              stepMinute={15}
              dateFormat={dateFormat}
              selectionMode={selectionMode}
              hideOnRangeSelection
              view={view}
              readOnlyInput={readOnlyInput}
              inputClassName={inputClassName}
              className={classNames("p-inputtext-sm", {
                "p-invalid": touched && error,
                horizontal: horizontal,
              })}
              footerTemplate={showTime || timeOnly ? footer : undefined}
            />
            <div className="flex items-start p-error h-2 mt-1">
              <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>
            </div>
          </div>
        )
      }}
    </Field>
  )
}

type Props = {
  field: string
  stringFormatType?: FormatTypes
  label?: string
  className?: string
  dateFormat?: string
  showTime?: boolean
  view?: "date" | "month" | "year" | undefined
  timeOnly?: boolean
  minDate?: Date
  maxDate?: Date
  selectionMode?: "single" | "multiple" | "range"
  readOnlyInput?: boolean
  horizontal?: boolean
  inputClassName?: string
  showIcon?: boolean
  validation?(value: string): void
  onChange?(value?: Date | Date[] | (Date | null)[]): void
}

export { DateField }
