import { type CodeableConcept, type Coding } from "fhir"

import { SYSTEM_VALUES } from "system-values"

const medicineClassificationCodings = [
  { code: "mental-wellness", system: "http://chartedhealth.com/fhir/therapeutic-class", display: "Mental wellness" },
  { code: "non-sterile", system: "http://chartedhealth.com/fhir/therapeutic-class", display: "Non sterile" },
  { code: "peptides", system: "http://chartedhealth.com/fhir/therapeutic-class", display: "Peptides" },
  {
    code: "sterile",
    system: "http://chartedhealth.com/fhir/therapeutic-class",
    display: "Sterile",
  },
] as Coding[]

const referencePriceQuantities = [30, 60, 90]

const allowedMKDoseFormsToGetReferencePrices: Record<string, boolean> = {
  capsule: true,
  "capsule-er": true,
  tablet: true,
  "tablet-er": true,
  troche: true,
  "rapid-odt": true,
}

export const requisitionEmailEnabled: CodeableConcept = {
  text: "Requisition email enabled",
  coding: [
    {
      code: "requisition-email-enabled",
      system: SYSTEM_VALUES.SETTING_DEFINITION_FLAG,
      display: "requisition-email-enabled",
    },
  ],
}

export const labSuppliedPhlebotomistEnabled: CodeableConcept = {
  text: "lab-supplied-phlebotomist",
  coding: [
    {
      code: "lab-supplied-phlebotomist",
      system: SYSTEM_VALUES.SETTING_DEFINITION_FLAG,
      display: "Phlebotomist supplied by Lab",
    },
  ],
}

export const labsAllowedToHaveSuppliedPhlebotomistSettingCodes: string[] = ["quest-enabled", "labcorp-enabled"]

export { allowedMKDoseFormsToGetReferencePrices, medicineClassificationCodings, referencePriceQuantities }
