import { getResources, PractitionerRole } from "fhir"
import { useQuery } from "@tanstack/react-query"

import { IClient, useClient } from "api"

import { practitionerRoleQueryKeys } from "../query-keys"

const usePractitionerRolePractitioner = (practId?: string) => {
  const { search } = useClient(IClient.AIDBOX)
  const queryKey = practitionerRoleQueryKeys.practId(practId ?? "")

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(practId ? { practitioner: practId } : {}),
      })
      const bundle = await search(`PractitionerRole`, filters, undefined, signal)
      const prs = getResources<PractitionerRole>(bundle)

      return {
        prs,
      }
    },
    meta: { context: { queryKey, practId } },
    enabled: !!practId,
    throwOnError: true,
  })

  return {
    roles: data?.prs,
    isLoading,
    isFetching,
  }
}

export { usePractitionerRolePractitioner }
