import { FC, useState } from "react"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Checkbox } from "primereact/checkbox"
import { ServiceRequest } from "fhir"

import { Button } from "commons"

import { getOrderIdentifierText } from "../utils"

const OrdersCancelDialog: FC<Props> = ({ orderToCancel, isCancelling, onHide, onCancel }) => {
  const [cancelReason, setCancelReason] = useState<string>()
  const [cancelFutureOrder, setCancelFutureOrder] = useState<"skip" | "stop">("skip")

  const handleHide = () => {
    onHide()
    setCancelFutureOrder("skip")
    setCancelReason(undefined)
  }

  return (
    <Dialog
      header={`Cancel order ${getOrderIdentifierText(orderToCancel)}`}
      visible={!!orderToCancel || isCancelling}
      draggable={false}
      style={{ width: "35vw" }}
      onHide={handleHide}
      footer={
        <div className="mt-2">
          <Button
            label="Cancel order"
            disabled={!cancelReason}
            loading={isCancelling}
            onClick={() => onCancel({ cancelFutureOrder, cancelReason: cancelReason ?? "" })}
          />
        </div>
      }
    >
      <label>Please provide a reason</label>
      <InputText className="w-full mt-2" value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} />
      <div className="flex items-center mt-3">
        <Checkbox
          name="status"
          checked={cancelFutureOrder === "stop"}
          onChange={() => setCancelFutureOrder(cancelFutureOrder === "stop" ? "skip" : "stop")}
        />
        <label htmlFor="status" className="p-checkbox-label ml-2">
          Cancel all future orders
        </label>
      </div>
    </Dialog>
  )
}

type Props = {
  orderToCancel: ServiceRequest | undefined
  isCancelling?: boolean
  onHide(): void
  onCancel(_: { cancelReason: string; cancelFutureOrder: "skip" | "stop" }): void
}

export { OrdersCancelDialog }
