import { AutoComplete } from "primereact/autocomplete"
import { useState, FC } from "react"
import { Reference } from "fhir"
import { Field, FieldProps, ErrorMessage } from "formik"
import { classNames } from "primereact/utils"
import { faBuilding, faLocationDot, faTimesCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useOrganizationRefs } from "organizations"
import { getStringAddress } from "utils"

const OrganizationAutocomplete: FC<Props> = ({
  field,
  label,
  className,
  horizontal,
  inputClassName,
  organazationType,
  disabled,
  validation,
}) => {
  const [filter, setFilter] = useState("")
  const { organizationRefs, organizations, isFetching, isLoading } = useOrganizationRefs(filter, organazationType)

  const orgTemplate = (option: Reference) => {
    const org = organizations?.[option.id!]
    return (
      org && (
        <div className="text-sm">
          <div className="font-bold">
            <FontAwesomeIcon icon={faBuilding} className="fa-fw mr-1" />
            <span title="Organization" className="text-base">
              {`${org.name} [${org.partOf?.display ?? "root"}]`}
            </span>
          </div>
          <div title="Address">
            <FontAwesomeIcon icon={faLocationDot} className="fa-fw mr-1" />
            {getStringAddress(org.address?.[0])}
          </div>
        </div>
      )
    )
  }

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => {
        return (
          <div
            className={classNames(
              "field relative ",
              horizontal ? "inline-flex justify-between" : "flex flex-col",
              className,
            )}
          >
            {label && (
              <label
                htmlFor={name}
                className={classNames("text-sm font-medium text-gray-700 mb-2", {
                  "mr-3 mb-0 mt-2": horizontal,
                })}
              >
                {label}
              </label>
            )}
            <div className="flex flex-col w-full">
              {value?.id && !isLoading && !isFetching && (
                <ClearInputAction className="right-3" onClick={() => setFieldValue(name, "")} />
              )}
              <AutoComplete
                id={name}
                name={name}
                value={typeof value === "object" ? value.display : value}
                field={name}
                disabled={disabled}
                suggestions={organizationRefs}
                completeMethod={(e) => setFilter(e.query)}
                onChange={(e) => {
                  setFieldValue(name, e.value)
                }}
                itemTemplate={orgTemplate}
                onSelect={(e) => {
                  setFieldValue(name, { id: e.value.id, display: e.value.display, resourceType: e.value.resourceType })
                }}
                className={classNames(
                  "p-inputtext-sm",
                  {
                    "p-invalid": touched && error,
                    horizontal: horizontal,
                  },
                  inputClassName,
                )}
                inputClassName={inputClassName}
              />
              <div className="flex items-start p-error h-2 mt-1">
                <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>
              </div>
            </div>
          </div>
        )
      }}
    </Field>
  )
}

const ClearInputAction: React.FC<{ className?: string; onClick: () => void }> = ({
  className = "right-2",
  onClick,
}: {
  className?: string
  onClick: () => void
}) => (
  <div title="Clear filter" className={`absolute cursor-pointer h-2/4 mb-8 flex items-end z-10 ${className}`}>
    <FontAwesomeIcon icon={faTimesCircle} className="text-md" onClick={onClick} />
  </div>
)

export { OrganizationAutocomplete }

type Props = {
  field: string
  label?: string
  className?: string
  isReadonly?: boolean
  horizontal?: boolean
  inputClassName?: string
  organazationType?: string
  disabled?: boolean
  validation?(value: string): void
}
