import { useEffect, useId, useState } from "react"
import { codeableConceptAsString, PractitionerRole } from "fhir"
import { Dialog } from "primereact/dialog"
import { faBuilding } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faStethoscope } from "@fortawesome/pro-regular-svg-icons"

import { getBadgeColor } from "utils"
import { SYSTEM_VALUES } from "system-values"
import { usePractitionerRolePractitioner } from "practitioner-role"
import { PractitionerApi, SearchInput, SkeletonLoader, StackedListContainer, StackedListItemProps } from "commons"

const PractitionerRoleModal = ({ pract, visible, setVisible }: Props) => {
  const [filteredRoles, setFilteredRoles] = useState<PractitionerRole[] | undefined>(undefined)
  const { roles, isLoading, isFetching } = usePractitionerRolePractitioner(pract?.practitioner.id)

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={3} loaderType="list" />

  useEffect(() => {
    setFilteredRoles(roles)
  }, [roles])

  const onSearch = (searchText?: string) => {
    if (searchText) {
      setFilteredRoles(roles?.filter((r) => r.organization?.display?.toLowerCase()?.includes(searchText.toLowerCase())))
    } else {
      setFilteredRoles(roles)
    }
  }

  return (
    <Dialog
      header="Roles"
      closable={true}
      visible={visible}
      className="w-full md:w-[70%] lg:w-[35%] m-2 lg:h-[50%] "
      onHide={() => setVisible(false)}
    >
      <>
        <div className="absolute w-[95%] bg-white pb-2">
          <SearchInput search={onSearch} className=" w-[95%]" />
        </div>
        <div className="flex flex-col overflow-auto grow pt-8">
          {isLoading || isFetching ? (
            loader()
          ) : filteredRoles?.length ? (
            <StackedListContainer
              itemsClassName="px-2 py-4"
              data={filteredRoles}
              itemModelBuilder={(item) => itemModel(item)}
            />
          ) : (
            <div className="flex flex-col items-center justify-center py-5">
              <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
              <p className="text-slate-500 text-xs pt-1">No Pratitioner Role</p>
            </div>
          )}
        </div>
      </>
    </Dialog>
  )
}
const itemModel = (pr: PractitionerRole): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        {
          name: "Code",
          value: codeableConceptAsString(pr.code?.[0]),
        },
      ],
    },
    {
      lineItems: [
        {
          name: "Organization",
          value: pr.organization?.display ?? "No organization",
          icon: faBuilding,
        },
        ...(pr.relatedPractitioner?.some(
          (rp) =>
            rp.relationType.code === "supervising-practitioner" &&
            rp.relationType.system === SYSTEM_VALUES.RELATED_PRACTITIONER,
        )
          ? [
              {
                name: "Supervising Physician",
                value: pr.relatedPractitioner.find((rp) => rp.relationType.code === "supervising-practitioner")
                  ?.practitioner.display,
                icon: faStethoscope,
              },
            ]
          : []),
      ],
    },
    ...(pr.identifier?.length
      ? [
          {
            withVerticalDivider: true,
            lineItems: pr.identifier
              ?.filter(({ type }) => !!type)
              ?.map(({ type, value }) => ({
                name: codeableConceptAsString(type),
                value: `${codeableConceptAsString(type)}: ${value}`,
              })),
          },
        ]
      : []),
  ],
  badge: getBadgeColor(pr.active ? "active" : "deactivated"),
})

type Props = {
  pract?: PractitionerApi
  visible: boolean
  setVisible(visible: boolean): void
}

export { PractitionerRoleModal }
