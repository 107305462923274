import { classNames } from "primereact/utils"
import { Reference } from "fhir"
import { ErrorMessage, Field, FieldProps } from "formik"
import { AutoComplete } from "primereact/autocomplete"
import { FC, startTransition, useEffect, useRef, useState } from "react"

import { usePatientsRefs } from "patients"

const AutoCompletePatientField: FC<Props> = ({ field, label, className, isReadonly, horizontal, inputClassName }) => {
  const [filter, setFilter] = useState<string | undefined>()
  const { isLoading, patientRefs } = usePatientsRefs(filter)
  const [filteredItems, setFilteredItems] = useState<Reference[]>()
  const isLoadingRef = useRef(isLoading)

  useEffect(() => {
    if (!isLoading && isLoadingRef.current) {
      isLoadingRef.current = false
      setFilteredItems(patientRefs ?? [])
    }

    isLoadingRef.current = isLoading
  }, [patientRefs, isLoading])

  return (
    <Field name={field}>
      {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => (
        <div
          className={classNames(
            "field relative",
            horizontal ? "inline-flex justify-between" : "flex flex-col",
            className,
          )}
        >
          {label && (
            <label
              htmlFor={name}
              className={classNames("text-sm font-medium text-gray-700 mb-2", { "mr-3 mb-0 mt-2": horizontal })}
            >
              {label}
            </label>
          )}
          <div className="flex flex-col w-full">
            <AutoComplete
              id={name}
              name={name}
              suggestions={filteredItems}
              field="display"
              minLength={3}
              delay={400}
              readOnly={isReadonly}
              completeMethod={(e) => {
                if (e.query.length >= 3) {
                  startTransition(() => setFilter(e.query))
                }
              }}
              onBlur={() => {
                if (!(value as Reference)?.id) {
                  setFilter("")
                  setFieldValue(name, "")
                }
              }}
              onChange={({ value }) => {
                setFieldValue(name, value ? { display: value } : "")
              }}
              onSelect={({ value }) => {
                setFieldValue(name, {
                  id: value.id,
                  display: value.display,
                  resourceType: value.resourceType,
                })
              }}
              value={value?.display}
              className={classNames(
                "p-inputtext-sm",
                {
                  "p-invalid": touched && error,
                  horizontal: horizontal,
                },
                inputClassName,
              )}
              inputClassName={inputClassName}
            />
            <div className="flex items-start p-error h-2 mt-1">
              <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>
            </div>
          </div>
        </div>
      )}
    </Field>
  )
}

type Props = {
  field: string
  label?: string
  className?: string
  isReadonly?: boolean
  horizontal?: boolean
  inputClassName?: string
}

export { AutoCompletePatientField }
