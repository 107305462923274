import { MenuItem } from "primereact/menuitem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faUserDoctorHair } from "@fortawesome/pro-solid-svg-icons"
import { faCancel, faCheck, faCopy, faEdit, faPaperPlane, faVials } from "@fortawesome/pro-light-svg-icons"
import { getFirstEmail, humanNameAsString } from "fhir"
import { Tooltip } from "primereact/tooltip"

import { AvatarImage, BadgeProps, StackedListItemProps, PractitionerApi } from "commons"
import { IDENTIFIER_CODE } from "data"
import { copyToClipboard, displayNotificationSuccess } from "utils"

const practModelBuilder = (
  pract: PractitionerApi,
  editPractitioner: (pract: PractitionerApi) => void,
  handleShowActiveDeactiveModal: (pract: PractitionerApi) => void,
  handlePractitionerInvite: (pract: PractitionerApi) => void,
  handlePractitionerLabSetup: (pract: PractitionerApi) => void,
  viewRoles: (pract: PractitionerApi) => void,
): StackedListItemProps => {
  const hasNPI = !!pract.practitioner?.identifier?.some((i) => i.type?.coding?.[0].code === IDENTIFIER_CODE.NPI)
  const alreadyInvited = !!pract?.b2cUserId

  const allowResendInvite = pract.status !== null && pract.status?.statusCode !== "active"

  const getImage = () => {
    return (
      <AvatarImage
        name={humanNameAsString(pract.practitioner?.name?.[0]) ?? ""}
        photoUrl={pract.practitioner?.photo?.[0]?.url}
        className="h-10 w-10 rounded-full"
      />
    )
  }

  const getMenuItems = () => {
    const menuItems: MenuItem[] = [
      {
        label: "Edit",
        icon: <FontAwesomeIcon icon={faEdit} size="sm" className="mr-2" />,
        command: () => editPractitioner(pract),
      },
      {
        label: pract.practitioner?.active === false ? "Activate" : "Deactivate",
        icon: <FontAwesomeIcon icon={pract.practitioner?.active ? faCancel : faCheck} size="sm" className="mr-2" />,
        command: () => handleShowActiveDeactiveModal(pract),
      },
      {
        label: allowResendInvite ? "Resend Invite" : "Invite",
        icon: <FontAwesomeIcon icon={faPaperPlane} size="sm" className="mr-2" />,
        command: () => handlePractitionerInvite(pract),
        disabled: alreadyInvited,
      },
      {
        label: "Lab setup",
        icon: (
          <>
            {!hasNPI && (
              <Tooltip content="This operation requires an NPI identifier" target=".lab-setup" position="left" />
            )}
            <FontAwesomeIcon icon={faVials} size="sm" className="mr-2" />
          </>
        ),
        command: () => handlePractitionerLabSetup(pract),
        disabled: !hasNPI,
        className: "lab-setup",
      },
      {
        label: "Copy id",
        icon: <FontAwesomeIcon icon={faCopy} size="sm" className="mr-2" />,
        command: () =>
          copyToClipboard(pract.practitioner.id as string, () => displayNotificationSuccess("Copied to clipboard")),
      },
    ]

    return menuItems
  }

  const getBadgeProps = (): BadgeProps => ({
    text: pract.practitioner?.active === false ? "deactivated" : " active",
    colorStyle: pract.practitioner?.active === false ? "red" : "green",
  })

  return {
    leftData: [
      { lineItems: [{ name: "Name", value: humanNameAsString(pract.practitioner?.name?.[0]) }] },
      {
        lineItems: [
          { name: "Email", value: getFirstEmail(pract.practitioner?.telecom) ?? "No email provided", icon: faEnvelope },
          {
            name: "Practitioner Roles",
            value: "Roles",
            icon: faUserDoctorHair,
            onClick: () => viewRoles(pract),
          },
        ],
      },
    ],
    badge: getBadgeProps(),
    menu: getMenuItems(),
    image: getImage(),
  }
}

export { practModelBuilder }
