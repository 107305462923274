import { FC, useId, useState } from "react"
import pluralize from "pluralize"
import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import { ServiceRequest } from "fhir"

import {
  EmptyMessage,
  InfiniteScroll,
  MasterDetailView,
  SkeletonLoader,
  StackedListContainer,
  useFiltersContext,
  useScreenContext,
} from "commons"
import { useLoginContext } from "security"
import { isLabOrder, isMedicationOrder, isRxOrder } from "data"

import { Order, OrderFilter } from "../types"
import { OrdersFiltersFormContiner } from "./OrdersFiltersFormContainer"
import { orderModelBuilder } from "./orderModelBuilder"
import { useCancelMrOrder, useOrganizationOrders, useRevokeLabOrder } from "../hooks"
import { OrdersCancelDialog } from "./OrdersCancelDialog"

const OrdersContainer: FC = () => {
  const loaderKey = useId()
  const { isRootAdmin } = useLoginContext()
  const { isSmallScreen } = useScreenContext()

  const navigate = (domain: string) => {
    window.open(domain, "_blank", "noopener")
  }

  const {
    searchText,
    filters: { authored, occurrence, organization, patient, status, type },
    hasActiveFilters,
    onFilter,
    onSearch,
    onClearFilters,
  } = useFiltersContext<OrderFilter>()

  const { orders, count, total, isLoading, hasNextPage, fetchNextPage } = useOrganizationOrders(
    organization?.id,
    type,
    patient?.id,
    undefined,
    status?.join(","),
    authored,
    occurrence,
    searchText,
  )

  const showOrder = ({ sr, ehrUrl, orgId }: Order) => {
    const cleanUrl = (str: string) => str.replace(/([^:]\/)\/+/g, "$1")
    let url = `${ehrUrl}/orgs/${orgId}/patients/${sr.subject.id}`

    if (isMedicationOrder(sr)) {
      const view = isRxOrder(sr) ? RX_VIEW : NUTRA_VIEW

      url = cleanUrl(
        `${url}?${DEFAULT_KP_VIEW}&view=${view}&subview=${sr?.status === "active" ? "orders" : "history"}&order=${
          sr?.id
        }`,
      )
    } else if (isLabOrder(sr)) {
      url = cleanUrl(`${url}?${DEFAULT_KP_VIEW}&view=${LAB_VIEW}&order=${sr?.id}`)
    }

    navigate(url)
  }

  const [orderToCancel, setOrderToCancel] = useState<ServiceRequest>()

  const closeCancelDialog = () => {
    setOrderToCancel(undefined)
  }

  const { cancelMrOrder, isCancelling } = useCancelMrOrder(undefined, closeCancelDialog)
  const { revokeLabOrder, isRevoking } = useRevokeLabOrder(undefined, closeCancelDialog)

  const cancelOrder = ({
    cancelFutureOrder,
    cancelReason,
  }: {
    cancelReason: string
    cancelFutureOrder: "skip" | "stop"
  }) => {
    if (isMedicationOrder(orderToCancel as ServiceRequest))
      cancelMrOrder({
        order: orderToCancel as ServiceRequest,
        cancelReason,
        cancelMode: cancelFutureOrder,
      })
    else revokeLabOrder({ order: orderToCancel as ServiceRequest, revokeReason: cancelReason })
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <MasterDetailView
      onSearch={onSearch}
      containerClassName="flex flex-col overflow-y-auto h-full w-full"
      searchText={searchText}
      placeholder="Search orders"
      headerText={`Showing ${count} ${pluralize("order", count)} of ${total} found`}
      loading={isLoading}
      filters={
        <OrdersFiltersFormContiner
          initialValues={{
            type,
            patient,
            organization,
            searchText,
            status,
            authored,
            occurrence,
          }}
          onSearch={onFilter}
          onClearFilters={onClearFilters}
          hasActiveFilters={hasActiveFilters}
          isSmallViewport={isSmallScreen}
        />
      }
      isSmallViewport={isSmallScreen}
      hasActiveFilters={hasActiveFilters}
    >
      {isLoading ? (
        loader()
      ) : !orders.length ? (
        <EmptyMessage icon={faSearch} message="No Orders Found" subMessage={false} />
      ) : (
        <InfiniteScroll useWindow={false} hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            itemPadding
            data={orders}
            itemModelBuilder={(item) =>
              orderModelBuilder(item, () => showOrder(item), isRootAdmin ? () => setOrderToCancel(item.sr) : undefined)
            }
          />
        </InfiniteScroll>
      )}

      <OrdersCancelDialog
        orderToCancel={orderToCancel}
        onCancel={cancelOrder}
        onHide={closeCancelDialog}
        isCancelling={isCancelling || isRevoking}
      />
    </MasterDetailView>
  )
}

const NUTRA_VIEW = "nutraceuticals"
const RX_VIEW = "eprescribe"
const DEFAULT_KP_VIEW = "kp=patient-information"
const LAB_VIEW = "labs"

export { OrdersContainer }
